import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

import Logo from '../../images/logo_pvereecken.png';
import Bkg from '../../images/bkg-intro.jpg';
import { MdHome, MdEmail, MdPhone, MdEvent } from 'react-icons/md';

function User() {
	return (
		<section
			className='user grid__container'
			id='about'
			style={{ backgroundImage: 'url(' + Bkg + ')' }}
		>
			<figure className='user__avatar' aria-hidden={true}>
				<img src={Logo} alt='Logo PVereecken' />
			</figure>

			<h1 className='user__name'>Pénélope Vereecken</h1>
			<p className='user__profession'>Développeuse front-end</p>

			<div className='user__infos'>
				<address className='user__info'>
					<MdHome aria-hidden={true} /> 35 rue de l'abbaye de Cluny,
					<br />
					59520 Marquette-Lez-Lille
				</address>
				<p className='user__info'>
					<MdPhone aria-hidden={true} />{' '}
					<a href='tel:+33664198219'>06 64 19 82 19</a>
				</p>
				<p className='user__info'>
					<MdEmail aria-hidden={true} />{' '}
					<Link to='contact' smooth={true} offset={-70} duration={500}>
						penelope.vereecken@gmail.com
					</Link>
				</p>
				<p className='user__info'>
					<MdEvent aria-hidden={true} /> Date de naissance : 20 octobre 1987
				</p>
			</div>

			<div className='user__profil mb-5'>
				<p>
					Je suis tombée dans l'univers du web en 2008. Après avoir beaucoup
					tatonné et appris en autodidacte, je me suis orientée vers une
					formation afin d'élargir mes domaines de connaissances et compétences
					dans les domaines du multimédia. Depuis, j'ai approfondi ces savoirs
					grâce mon expérience en entreprise, mais aussi grâce à de nombreuses
					ressources glanées sur net ou via l'intermédiaire de collègues.
				</p>
				<p>
					Vous trouverez ici toutes mes expériences et formations, de ma sortie
					de DUT jusqu'à ce jour.
				</p>
			</div>
		</section>
	);
}

export default User;
