import React from 'react';
import { FaTools, FaRegLightbulb } from 'react-icons/fa';
import { GrCode } from 'react-icons/gr';
import { MdComputer } from 'react-icons/md';

function Skills() {
	return (
		<>
			{/*
                expert
                advanced
                intermediate
                basic
                learning
            */}
			<section className='skill-area' id='skills'>
				<h2 className='h2'>Compétences</h2>
				<p>
					De l'intégration au développement web front-end, en passant par le
					webmastering.
				</p>

				<div className='wrapper-skills'>
					<div className='skills'>
						<h3 className='h3'>
							<GrCode aria-hidden={true} /> Langages / Frameworks
						</h3>
						<p>
							HTML5, CSS3, Sass, Javascript, jQuery, React, NextJs, Gatsby,
							Bulma, Boostrap
						</p>
					</div>
					<div className='skills'>
						<h3 className='h3'>
							<FaTools aria-hidden={true} /> Outils
						</h3>
						<p>
							Git, Azure DevOps, Bitbucket, Jira, Adobe XD, Docker, Pantheon
						</p>
					</div>
					<div className='skills'>
						<h3 className='h3'>
							<MdComputer aria-hidden={true} /> CMS
						</h3>
						<p>Wordpress, Strapi, ContentFul</p>
					</div>
					<div className='skills'>
						<h3 className='h3'>
							<FaRegLightbulb aria-hidden={true} /> Sensibilisation
						</h3>
						<p>Responsive Design, SEO, Accessibilité</p>
					</div>
				</div>
			</section>
		</>
	);
}

export default Skills;
