const dataInterests = [
	{
		visuel: '/images/blog-lecture.jpg',
		alt: '',
		title: 'Blog de Lecture',
		text: "Lors du confinement du printemps 2020, j'ai ressenti le besoin de m'évader et la lecture a toujours rempli ce rôle à la perfection pour moi.<br />Etant donné que j'avais besoin d'une remise à niveau sur <i>Wordpress</i>, j'ai décidé de me créer un blog où je partage mes critiques littéraires.",
		links: [
			{
				text: 'Les Lectures de Miettes',
				link: 'https://lectures-miettes.fr/',
			},
		],
	},
	{
		visuel: '/images/forum.jpg',
		alt: '',
		title: 'Forums de type "ForumActif"',
		text: "C'est en 2008 que je découvre l'univers des forums et la plateforme \"ForumActif\". Ce qui me plait le plus dans les forums, c'est le partage, l'entraide, la création, l'administration & la dynamisation de forum. A ce jour, je n'ai que 2 forums à mon actif :",
		links: [
			{
				text: 'Entre2Livres',
				link: 'https://entre2livres.forumactif.com/',
			},
			{
				text: 'La Tambouille des Râleuses',
				link: 'https://tambouille-raleuses.forumactif.com/',
			},
		],
	},
	/*{
		visuel: '/images/dp.jpg',
		alt: 'toolkit de base pour faire du Diamond Painting',
		title: 'Diamond Painting',
		text: "En semaine, je passe quasi 10h par jour sur le pc. En plus de mon boulot, mes loisirs sont souvent sur l'ordinateur. J'avais donc envie de quelques choses \"sans écran\". C'est par l'intermédiaire d'un membre de la famille que j'ai découvert cette activité et je dois dire que ça me permet de vraiment me vider la tête.",
		links: [
			{
				text: 'En savoir plus',
				link: 'https://lectures-miettes.pvereecken.fr/tag/diamond-painting/',
			},
		],
	},*/
];

export default dataInterests;
