const dataFormations = [
	{
		title: 'UX Design et ergonomie des sites Web',
		date: 'Septembre 2023',
		location: 'Dispensé par Cegos',
		text: '<p>Formation sur deux jours dispensée par l\'organisme de formation Cegos.</p><p><a href="https://www.cegos.fr/formations/marketing-marketing-digital/ux-design-et-ergonomie-des-sites-web" target="_blank">Accèder au détail de la formation UX design et ergonomie des sites Web</a>',
	},
	{
		title: 'Sensibilisation Accessibilité',
		date: 'Septembre 2023',
		location: 'Dispensé par Dawan',
		text: '<p>Formation sur deux jours dispensée par l\'organisme de formation Dawan.</p><p><a href="https://www.dawan.fr/formations/sites-web/accessibilite/accessibilite--developper-et-coder-des-sites-accessibles" target="_blank">Accèder au détail de la formation Développer et coder des sites accessibles</a>',
	},
	{
		title: 'Dev Fullstack (projet MERN)',
		date: 'Avril / Mai 2023',
		location: 'Auto-formation',
		text: '<p>Suivi d\'un tutoriel sur <a href="https://www.youtube.com/watch?v=SUPDFHuvhRc" target="_blank">YouTube</a></p><ul><li>Initialisation d\'un backend sous NodeJs, Express et MongoDB</li><li>Initialisation d\'une BDD sous MongoDB</li><li>Mise en place d\'une API pour un réseau social</li><li>Réalisation du frontend sous React</li></ul>',
	},
	{
		title: 'MongoDB',
		date: 'Mars 2023',
		location: 'Auto-formation',
		text: '<p>Suivi d\'un tutoriel sur <a href="https://www.youtube.com/watch?v=SjBWysPZpIU&list=PLTCE7CKb1F5ATrz_sZkRyoNY4T_uu9no-&index=26" target="_blank">YouTube</a></p><ul><li>Découverte et prise en main de MongoCompass</li><li>Découverte et prise en main de MongoShell</li><li>Découverte et prise en main des requêtes les plus courantes</li></ul>',
	},
	{
		title: 'NodeJs',
		date: 'Février 2023',
		location: 'Auto-formation',
		text: '<p>Suivi d\'un tutoriel sur <a href="https://www.youtube.com/watch?v=kAdzI23fY78&list=PLT2KSPhMMiqq5VCDawcDMS_pQOhU77wl-&index=2" target="_blank">YouTube</a></p><ul><li>Découverte et prise en main du framework</li></ul>',
	},
	{
		title: 'Accessibilité',
		date: 'Février 2023',
		location: 'Auto-formation',
		text: '<p><a href="https://www.youtube.com/watch?v=_5zIrh-LDto" target="_blank">Webinar</a> et autres vidéos de présentation suivies sur Youtube</p><ul><li>Bonnes pratiques</li><li>Outils utiles</li><li>Erreurs à éviter</li><li>WAI-ARIA</li></ul>',
	},
	{
		title: 'Reachfive',
		date: '24/25 février 2022',
		location: 'Formation par une équipe ReachFive',
		text: "<ul><li>Présentation de l'outil</li><li>Prise en main de l'outil</li><li>Quelques manipulations/cas pratiques</li></ul>",
	},
	{
		title: 'Strapi, NextJS',
		date: 'Janvier 2022',
		location: 'Auto-formation',
		text: '<ul><li>Démarrage d\'un projet NextJS</li><li>Découverte et initialisation du CMS</li><li>Mapping "Strapi/NextJS"</li></ul>',
	},
	{
		title: 'React, ContentFul',
		date: 'Février 2021',
		location: 'Auto-formation',
		text: '<ul><li>Démarrage d\'un projet React</li><li>Découverte et initialisation du CMS</li><li>Mapping "React/Contentful"</li></ul>',
	},
	{
		title: 'Shopify',
		date: 'Janvier 2021',
		location: 'Auto-formation',
		text: '<ul><li>Découverte et prise en main du CMS</li><li>Comment créer des thèmes personnalisés</li></ul>',
	},
	{
		title: 'Initiation Agile',
		date: 'Décembre 2020',
		location: 'Espilon (formation interne)',
		text: '<ul><li>Présentation de la méthode Agile</li><li>Concepts de base</li><li>Initiation</li></ul>',
	},
	{
		title: 'Wordpress',
		date: 'Mai 2020',
		location: 'Auto-formation',
		text: '<ul><li>Remise à niveau et prise en main du CMS</li><li>Comment créer des thèmes personnalisés</li><li>Création de widgets personnalisés</li><li>Mise en place et utilisation d’extensions (WPForms, Elementor, Ultimate Members, Email Subscribers & Newsletters, etc)</li><li>Réalisation d’un blog personnel</li></ul>',
	},
	{
		title: 'Drupal',
		date: 'Février 2020',
		location: 'Auto-formation',
		text: '<ul><li>Découverte et prise en main du CMS</li><li>Comment créer un site de A à Z</li><li>Comment créer des thèmes personnalisés</li><li>Comment créer des landing page</li><li>Mise en place et utilisation d’extensions (Devel, Paragraphs, Webform, Layout Builder)</li><li>Réalisation d’un site « entrainement »</li></ul>',
	},
	{
		title: 'Git',
		date: 'Février 2020',
		location: 'Espilon (formation interne)',
		text: '<ul><li>Présentation, fonctionnement et mécanique de base</li></ul>',
	},
	{
		title: 'Initiation NodeJs',
		date: 'Juillet 2019',
		location: 'ETO (formation interne)',
		text: '<ul><li>Présentation</li><li>Fonctionnement</li><li>Concepts de base</li><li>Exemples d’utilisation</li></ul>',
	},
	{
		title: 'Initiation Angular',
		date: 'Février 2018',
		location: 'ETO (formation interne)',
		text: '<ul><li>Présentation du langage</li><li>Concept</li><li>Bonnes pratiques</li><li>Prise en main & utilisation au sein de nos projets internes</li></ul>',
	},
	{
		title: 'Initiation Javascript/jQuery',
		date: 'Décembre 2015',
		location: 'ETO (formation interne)',
		text: '<ul><li>Présentation</li><li>Bonnes pratiques</li><li>Mise en pratique (formation sur plusieurs jours)</li></ul>',
	},
	{
		title: 'DUT Services et Réseaux de Communication (SRC)',
		date: 'Septembre 2008 - Juin 2010',
		location: 'IUT de Lens',
		text: '<ul><li>Programmation <span class="txt-small">(HTML, CSS, PHP, Javascript, Java, Flash)</span></li><li>Audiovisuel, Communication, Marketing, Graphisme <span class="txt-small">(2D, 3D)</span>, Photographie, Design, Evenementiel, Gestion de projet</li><li>Outils utilisés : Photoshop, Illustrator, InDesign, Flash, Eclypse, Linux, 3DSMax, Adobe Premiere</li><li>Parcours « programmation » suivi au S4</li></ul>',
	},
];

export default dataFormations;
