import React from 'react';
import SlideToggle from "react-slide-toggle";
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';

function Experiences({datas}) {
    return (
        <section className="cursus" id="experience">
            <h2 className="h2">Experiences professionnelles</h2>
            <p>De ma sortie de DUT jusqu'à aujourd'hui...</p>

            {datas.map( (item, index) => 
                <div data-aos={`${index % 2 === 0 ? 'fade-right' : 'fade-left'}`} className="grid__row" key={index}>
                    <div className="grid__item grid_timeline"></div>
                    <div className="grid__item">
                        <p className="grid__date">
                            {item.date}
                        </p>
                        <h3 className="grid__title">
                            {item.title}
                        </h3>
                        <p className="grid__location">
                            {item.location}
                        </p>
                        <ul className="grid__missions">
                            {item.missions.map( (mission, index) => 
                                <li className="grid__mission" key={index}>
                                    <SlideToggle
                                        collapsed 
                                        render={({ toggle, progress , setCollapsibleElement }) => (
                                            <>
                                                <p className="mission__viewmore" onClick={toggle}>
                                                    {mission.intitule} 
                                                    {progress <= 0.5 && 
                                                        <RiArrowDownSFill />
                                                    } 
                                                    {progress > 0.5 && 
                                                        <RiArrowUpSFill />
                                                    }
                                                </p>
                                                <div className="mission__detail" ref={setCollapsibleElement} dangerouslySetInnerHTML={{__html: mission.text}}></div>
                                            </>
                                        )}
                                    />
                                                                        
                                    <ul className="tags">
                                        {mission.tags.map( (tag, index) => 
                                            <li className="tag" key={index}>
                                                {tag}
                                            </li>
                                        )}
                                    </ul>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            )}

            
        </section>
    )
}

export default Experiences
