const dataExperiences = [
	{
		title: 'Développeuse front-end',
		date: 'Depuis Mai 2021',
		location: 'Epsilon France (Lille)',
		missions: [
			{
				intitule: 'Refonte graphique du D-Passport de Pernod-Ricard',
				text: "<p>Plateform d'apprentissage uniquement accessible par les employés Pernod-Ricard</p><ul><li>Mise à niveau de la charte graphique via le theme builder Elementor</li><li>Mise à jour du thème via le theme builder Elementor</li><li>Refonte des pages web via le page Builder Elementor</li><li>Création d'un plugin WordPress dédié</li><li>Mise en place de Custom Post Type et de Custom Field pour faciliter l'intrégation de nouveaux contenus</li><li>Mise à jour des traductions du site via le plugin Polylang</li><li>Responsive Design</li></ul>",
				tags: ['WordPress', 'Docker', 'Pantheon', 'Jira'],
			},
			{
				intitule: 'Digimail',
				text: "<p>Application web permettant de créer des emails via une interface user-friendly</p><ul><li>Mapping du front et de l'API dédiée créée par l'équipe.</li></ul>",
				tags: ['Figma', 'Bulma', 'React', 'Bitbucket', 'Azure DevObs', 'Jira'],
			},
			{
				intitule: 'SkillCast',
				text: "<p>Application web permettant de générer des vidéos de présentation utilisant des voix google et des avatars provenant de Synthésia</p><ul><li>Mise en place du front (création de components React, mise en place d'une charte graphique)</li><li>Intégration & dynamisation des pages via l'API dédiée créée par l'équipe.</li></ul>",
				tags: ['Figma', 'Bulma', 'React', 'Bitbucket', 'Azure DevObs', 'Jira'],
			},
			{
				intitule: 'Prodigious Talbox',
				text: "<p>Application web permettant de générer des voix de synthèse</p><ul><li>Création de wireframe</li><li>Mise en place du front (création de components React, mise en place d'une charte graphique)</li><li>Intégration & dynamisation des pages via l'API dédiée créée par l'équipe.</li><li>TMA</li></ul>",
				tags: ['Figma', 'Bulma', 'React', 'Bitbucket', 'Azure DevObs', 'Jira'],
			},
			{
				intitule: 'Refonte graphique du site Teoxane',
				text: '<ul><li>Mise à niveau de la charte graphique via le theme builder Elementor</li><li>Mise à jour du thème via le theme builder Elementor</li><li>Refonte des pages web via le page Builder Elementor</li><li>Responsive Design</li></ul>',
				tags: ['WordPress', 'Docker', 'BitBucket', 'Jira', 'AdobeXd'],
			},
			{
				intitule:
					'Mise en place du site interne pour le Challenge Total Energie',
				text: '<ul><li>Initialisation du Strapi</li><li>Création des <em>Content Type</em> dans Strapi</li><li>Création des components React</li><li>Initialisation du front et dynamisations de contenus via API Strapi & API dédiée</li><li>Responsive Design</li></ul>',
				tags: [
					'React',
					'Sass',
					'Strapi',
					'Responsive Design',
					'Azure DevOps',
					'BitBucket',
					'Jira',
					'AdobeXd',
				],
			},
			{
				intitule: 'Refonte du site Epsilon-France',
				text: '<ul><li>Mise en place d\'un frontend sous NextJS</li><li>Initialisation du strapi</li><li>Création des <em>Content Type</em> dans Strapi</li><li>Création des components React</li><li>Initialisation du front et dynamisations de contenus via API Strapi</li><li>Responsive Design</li><li>"Audit" d\'accessibilité</li></ul>',
				tags: [
					'NextJs',
					'Sass',
					'Strapi',
					'Responsive Design',
					'Azure DevOps',
					'Jira',
					'AdobeXd',
				],
			},
			{
				intitule: 'Refonte du site Publicis Conseil',
				text: "<ul><li>Mise en place d'un frontend sous React</li><li>Création des components React</li><li>Création des <em>Content Models</em> dans Contentful</li><li>Initialisation du site et des contenus</li><li>Mise en place du multi-langue</li><li>Rédaction de documentation d'utilisation du CMS</li><li>Responsive Design</li><li>Mise en conformité RGPD</li></ul>",
				tags: [
					'React',
					'Gatsby',
					'Contentful',
					'Sass',
					'Bulma',
					'Responsive Design',
					'Bitbucket',
					'Jira',
				],
			},
		],
	},
	{
		title: 'Intégratrice web',
		date: 'Décembre 2014 - Mai 2021',
		location: 'Epsilon France / ETO (Lille)',
		missions: [
			{
				intitule: 'Datawall La Poste',
				text: '<ul><li>Mise en place d’un espace d’administration pour créer et administrer des datawall (animations visuelles pour leurs différentes agences).</li><li>Proposition graphique.</li><li>Intégration de gabarits</li></ul>',
				tags: ['React', 'Sass', 'Bulma', 'Responsive Design', 'Azure DevOps'],
			},
			{
				intitule: 'Mercedes AMG',
				text: "<ul><li>Soutien pour l'équipe en charge du projet</li><li>Traitement des retours clients</li></ul>",
				tags: [
					'Drupal',
					'Sass',
					'Boostrap',
					'Responsive Design',
					'Azure DevOps',
					'Redmine',
				],
			},
			{
				intitule: 'Bonjour Docteur (Axa)',
				text: '<ul><li>Intégration HTML & CSS d’une nouvelle Homepage pour le service de consultation médicale en ligne</li><li>Intégration des contenus prismic dans les pages</li><li>Responsive Design</li></ul>',
				tags: ['React', 'Sass', 'Azure DevOps', 'Prismic', 'Responsive Design'],
			},
			{
				intitule: 'Maintenance des sites Orange',
				text: "<ul><li>Intégration de maquettes Zeplin dans le respect des standarts du W3C</li><li>Intégration à l’aide de grilles, permettant d’avoir un colonnage harmonieux</li><li>Utilisation du CMS propriétaire : création de templates, mise à jour des contenus, rédaction de documents </li><li>d’utilisation, formations</li><li>Compatibilité multi-navigateurs (Firefox, Chrome, Safari, Opéra, IE7 à IE11)</li><li>Responsive Design</li><li>TMA sur l'ensemble des sites d'Orange</li></ul>",
				tags: [
					'HTML5',
					'CSS3',
					'Javascript/jQuery',
					'Angular',
					'Boostrap',
					'Responsive Design',
					'Zeplin',
					'Visual Studio',
					'Mantis',
				],
			},
		],
	},
	{
		title: 'Intégratrice web',
		date: 'Décembre 2012 - Décembre 2014',
		location: 'Atecna (Lille)',
		missions: [
			{
				intitule: 'Maintenance des sites Orange (prestataire pour ETO)',
				text: "<ul><li>Découpage et intégration de maquettes Photoshop dans le respect des standarts du W3C</li><li>Intégration à l’aide de grilles, permettant d’avoir un colonnage harmonieux</li><li>Utilisation du CMS propriétaire : création de templates, mise à jour des contenus, rédaction de documents </li><li>d’utilisation, formations</li><li>Compatibilité multi-navigateurs (Firefox, Chrome, Safari, Opéra, IE7 à IE11)</li><li>TMA sur l'ensemble des sites d'Orange</li></ul>",
				tags: [
					'HTML5',
					'CSS3',
					'Javascript/jQuery',
					'Photoshop',
					'Visual Studio',
					'Mantis',
				],
			},
		],
	},
	{
		title: 'Intégratrice web',
		date: 'Septembre 2012 - Décembre 2012',
		location: 'Agence Régionale de Santé (Lille)',
		missions: [
			{
				intitule:
					'Mise en place d’un site Intranet d’informations statistiques et géographiques',
				text: '<ul><li>Réalisation d’un design en respect de la charte graphique des partenaires du projet</li><li>Mise en place du CMS Joomla!</li><li>Intégration des contenus</li><li>Rédaction de documents techniques de mise à jour et utilisation</li></ul>',
				tags: ['HTML', 'CSS', 'Joomla!', 'Photoshop'],
			},
		],
	},
	{
		title: 'Graphiste et intégratrice web',
		date: 'Mai 2010 - Juillet 2010',
		location: 'TelAndCom (Lille)',
		missions: [
			{
				intitule: 'Animation graphique et emailing du site e-commerce',
				text: "<ul><li>Réalisation de bandeaux publicitaire dans le respect de la charte graphique du site e-commerce</li><li>Réalisation d'emailing</li><li>Mise en place de pages promotionnelles (Landing Page)</li></ul>",
				tags: ['HTML', 'CSS', 'Photoshop', 'Illustrator'],
			},
		],
	},
];

export default dataExperiences;
