import React from "react";
import ProgressBar from "react-scroll-progress-bar"; //Add this line

export default class App extends React.Component {
    render() {
        return (
            <div aria-hidden={true} className="progressbar">
                <ProgressBar />
            </div>  
        );
    }
}