import React from 'react';
import dataFormations from "../../datas/Formation";
import Formations from "./Formations";
import dataExperiences from "../../datas/Experiences";
import Experiences from "./Experiences";

function FormationsExperiences() {
    return (
        <>
            <Experiences datas={dataExperiences} />
            <Formations datas={dataFormations} />  
        </>
    )
}

export default FormationsExperiences
