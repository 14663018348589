import React, { useEffect } from 'react';
import Aos from 'aos';

import './styles/global.scss';
import ScrollProgressBar from './components/ScrollProgressBar/ScrollProgressBar';
import Nav from './components/Navigation/Nav';
import User from './components/User/User';
import Skills from './components/Skills/Skills';
import Interests from './components/Interests/Interests';
import FormationsExperiences from './components/FormationsExperiences/FormationsExperiences';
import CTA from './components/Cta/Cta';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';

import DarkMode from './components/DarkMode/DarkMode';
import BackToTop from './components/Navigation/BackToTop';

function App() {
	useEffect(() => {
		Aos.init({
			duration: 2000,
		});
	}, []);

	return (
		<>
			<DarkMode />

			{/* ProgressBar */}
			<ScrollProgressBar />

			{/* Navbar */}
			<Nav />

			{/* Profil + info + a propos */}
			<User />

			{/* Competences */}
			<Skills />

			{/* Formations / Expériences */}
			<FormationsExperiences />

			{/* Format PDF 
			<CTA />*/}

			{/* Loisirs */}
			<Interests />

			{/* Contact */}
			<Contact />

			{/* Footer */}
			<Footer />

			<BackToTop />
		</>
	);
}

export default App;
