import React from 'react'

function Contact() {
    return (
			<section className='contact' style={{ minHeight: '50vh' }} id='contact'>
				<h2>Me contacter</h2>
				<p>
					Envie d'en savoir plus et de faire connaissance ?<br />
					N'hésitez pas à me contacter ci-dessous ou sur{' '}
					<a href='www.linkedin.com/in/penelope-vereecken' target='_blank'>
						mon Linkedin
					</a>
				</p>

				<form
					className='contact__form'
					name='contact'
					method='post'
					data-netlify='true'
					onSubmit='submit'
				>
					<input type='hidden' name='form-name' value='contact' />

					<label htmlFor='name-send'>Nom :</label>
					<input type='text' name='name-send' required />

					<label htmlFor='email-send'>Email :</label>
					<input type='email' name='email-send' required />

					<label htmlFor='message-send'>Message :</label>
					<textarea name='message-send' required></textarea>

					<button type='submit'>Envoyer</button>
				</form>
			</section>
		);
}

export default Contact
