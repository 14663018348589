import React from 'react';
import SlideToggle from "react-slide-toggle";
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri';

function Formations({datas}) {      
    return (
        <section className="cursus" id="cursus">
            <h2 className="h2">Formations</h2>
            <p>Tout ce qui pourrait vous être utile...</p>

            {datas.map( (item, index) => 
                <div data-aos={`${index % 2 === 0 ? 'fade-right' : 'fade-left'}`} className="grid__row" key={index}>
                    <div className="grid__item grid_timeline"></div>
                    <div className="grid__item">
                        <p className="grid__date">
                            {item.date}
                        </p>
                        <h3 className="grid__title">
                            {item.title}
                        </h3>
                        <p className="grid__location">
                            {item.location}
                        </p>
                        <SlideToggle
                            collapsed 
                            render={({ toggle, progress , setCollapsibleElement }) => (
                                <>
                                    <p className="cursus__viewmore" onClick={toggle}>
                                        En savoir +
                                        {progress <= 0.5 && 
                                            <RiArrowDownSFill />
                                        } 
                                        {progress > 0.5 && 
                                            <RiArrowUpSFill />
                                        }
                                    </p>
                                    <div className="cursus__detail" ref={setCollapsibleElement} dangerouslySetInnerHTML={{__html: item.text}}></div>
                                </>
                            )}
                        />
                    </div>
                </div>
            )}

            
        </section>
    )
}

export default Formations
