import React, {useState} from 'react';
import { BiArrowToTop } from "react-icons/bi";

const BackToTop = () =>{

    const [showScroll, setShowScroll] = useState(false)
  
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400){
        setShowScroll(true)
      } else if (showScroll && window.pageYOffset <= 400){
        setShowScroll(false)
      }
    };
  
    const scrollTop = () =>{
      window.scrollTo({top: 0, behavior: 'smooth'});
    };
  
    window.addEventListener('scroll', checkScrollTop)
  
    return (
          <BiArrowToTop className={`scrollTop ${showScroll ? 'show' : ''}`} onClick={scrollTop} />
    );
  }

export default BackToTop
