import React from 'react';
import Slider from 'react-slick';

import dataInterests from '../../datas/Interests';
import Bkg from '../../images/bkg-intro.jpg';

function Interests() {
	const datas = dataInterests;

	const settings = {
		arrows: false,
		dots: true,
		infinite: true,
		speed: 1000,
		slidesToShow: 1,
		slidesToScroll: 1,
	};

	return (
		<section
			className='interests'
			id='interest'
			style={{ backgroundImage: 'url(' + Bkg + ')' }}
		>
			<h2 className='h2'>Loisirs</h2>
			<p>
				Le web ne fait pas seulement partie de mon quotidien professionnel.
				<br />
				C’est avant tout une passion que j’ai depuis plusieurs années et avec
				laquelle je m’amuse chaque jour.
			</p>

			<Slider {...settings}>
				{datas.map((item, index) => (
					<div data-aos='zoom-in' className='card' key={index}>
						<h3 className='card__title'>{item.title}</h3>
						<img aria-hidden={true} className='card__visuel' src={item.visuel} alt={item.alt} />
						<p
							className='card__text'
							dangerouslySetInnerHTML={{ __html: item.text }}
						/>
						<ul className='card__links'>
							{item.links.map((link, index) => (
								<li key={index}>
									<a href={link.link} target='_blank'>
										{link.text}
									</a>
								</li>
							))}
						</ul>
					</div>
				))}
			</Slider>
		</section>
	);
}

export default Interests;
