import React from 'react';
import { useState } from 'react';
import { BiSun } from 'react-icons/bi';
import { MdBrightness2 } from 'react-icons/md';

function DarkMode() {

    const [themeMode, setThemeMode] = useState('light');
    
    let clickedClass = "clicked";
    const body = document.body;
    const lightTheme = "theme-light";
    const darkTheme = "theme-dark";
    let theme;

    if( localStorage ){
        theme = localStorage.getItem('theme');
    }

    if(theme === lightTheme || theme === darkTheme){
        body.classList.add(theme);
    }else{
        body.classList.add(lightTheme);
    }

    const switchTheme = e => {
        if(theme === darkTheme){
            body.classList.replace(darkTheme, lightTheme);
            e.target.classList.remove(clickedClass);
            localStorage.setItem('theme', lightTheme);
            theme = lightTheme;
        }else{
            body.classList.replace(lightTheme, darkTheme);
            e.target.classList.add(clickedClass);
            localStorage.setItem('theme', darkTheme);
            theme = darkTheme;
        }

        setThemeMode(theme);
    }

    return (
			<button
				className={theme === { darkTheme } ? { clickedClass } : ''}
				id='darkMode'
				onClick={(e) => switchTheme(e)}
				aria-label={themeMode === lightTheme || theme === lightTheme ? 'Passer en mode sombre' : 'Passer en mode clair'}
			>
				{themeMode === lightTheme || theme === lightTheme ? (
					<MdBrightness2 aria-hidden={true} />
				) : (
					<BiSun aria-hidden={true} />
				)}
			</button>
		);
}

export default DarkMode
