import React, { useState } from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';

function Nav() {
	const [showLinks, setShowLinks] = useState(false);

	const handleShowLinks = () => {
		setShowLinks(!showLinks);
	};

	const [isTop, setIsTop] = useState(true);
	const [isBottom, setIsBottom] = useState(false);

	const checkPosition = () => {
		let bodyHeight = document.body.offsetHeight;
		let clientHeight = document.documentElement.clientHeight;
		let scroll = window.pageYOffset;
		let contactPosition = document.getElementById('contact').offsetTop + 70; // Nav height

		// If top of the page ; set isTop to true
		if (!isTop && scroll === 0) {
			setIsTop(true);
		} else if (isTop && scroll !== 0) {
			setIsTop(false);
		}

		// If bottom of the page ; set isBottom to true
		if (
			!isBottom &&
			(scroll > contactPosition || scroll + clientHeight === bodyHeight)
		) {
			setIsBottom(true);
		} else if (
			isBottom &&
			(scroll < contactPosition || scroll + clientHeight !== bodyHeight)
		) {
			setIsBottom(false);
		}
	};
	window.addEventListener('scroll', checkPosition);

	return (
		<nav className='navbar'>
			<ul className={`navbar__links ${showLinks ? 'show-nav' : ''}`}>
				<li className='navbar__item'>
					<Link
						activeClass='active-link'
						to='about'
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
						className={`navbar__link ${isTop ? 'active-link' : ''}`}
						onClick={handleShowLinks}
					>
						À propos
					</Link>
				</li>
				<li className='navbar__item'>
					<Link
						activeClass='active-link'
						to='skills'
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
						className='navbar__link'
						onClick={handleShowLinks}
					>
						Compétences
					</Link>
				</li>
				<li className='navbar__item'>
					<Link
						activeClass='active-link'
						to='experience'
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
						className='navbar__link'
						onClick={handleShowLinks}
					>
						Expériences
					</Link>
				</li>
				<li className='navbar__item'>
					<Link
						activeClass='active-link'
						to='cursus'
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
						className='navbar__link'
						onClick={handleShowLinks}
					>
						Formations
					</Link>
				</li>
				{/* <li className="navbar__item">
                    <Link activeClass="active-link"
                        to="cv"
                        spy={true}
                        smooth={true}
                        offset={-70}
                        duration={500} 
                        className="navbar__link"
                        onClick={handleShowLinks}
                    >
                        CV en PDF
                    </Link>
    </li> */}
				<li className='navbar__item'>
					<Link
						activeClass='active-link'
						to='interest'
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
						className={`navbar__link ${isBottom ? 'not-active-link' : ''}`}
						onClick={handleShowLinks}
					>
						Loisirs
					</Link>
				</li>
				<li className='navbar__item'>
					<Link
						activeClass='active-link'
						to='contact'
						spy={true}
						smooth={true}
						offset={-70}
						duration={500}
						className={`navbar__link ${isBottom ? 'active-link' : ''}`}
						onClick={handleShowLinks}
					>
						Me contacter
					</Link>
				</li>
			</ul>
			<button
				className={`navbar__burger ${showLinks ? 'show-nav' : ''}`}
				onClick={showLinks ? 'Fermer le menu' : 'Ouvrir le menu'}
				aria-label={'Afficher'}
			>
				<span className='burger-line' aria-hidden={true}></span>
			</button>
		</nav>
	);
}

export default Nav;
