import React from 'react';

function Footer() {
	return (
		<footer className='footer'>
			<p>©2021 Pénélope Vereecken | Dernière mise à jour le 10/11/2023</p>
		</footer>
	);
}

export default Footer;
